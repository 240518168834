import _mock from '../_mock';
import { randomInArray } from '../utils';
import { getId } from '~helper/index';
import Facebook from 'public/assets/icons/facebook.svg';
import Instagram from 'public/assets/icons/instagram.svg';
import Linkedin from 'public/assets/icons/linkedin.svg';
import Twitter from 'public/assets/icons/twitter.svg';
import Youtube from 'public/assets/icons/youtube.svg';
import Pinterest from 'public/assets/icons/pinterest.svg';
import Tiktok from 'public/assets/icons/tiktok.svg';

export const _carouselsMembers = [...Array(6)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  role: _mock.role(index),
  avatar: `/assets/images/portraits/portrait_${index + 1}.jpg`,
}));

export const _addressBooks = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  receiver: _mock.name.fullName(index),
  fullAddress: _mock.address.fullAddress(index),
  phoneNumber: _mock.phoneNumber(index),
  addressType: index === 0 ? 'Home' : 'Office',
  isDefault: index === 0,
}));

export const _skills = [...Array(3)].map((_, index) => ({
  label: ['Development', 'Design', 'Marketing'][index],
  value: _mock.number.percent(index),
}));

export const _contacts = [...Array(20)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  username: _mock.name.fullName(index),
  avatar: _mock.image.avatar(index),
  address: _mock.address.fullAddress(index),
  phone: _mock.phoneNumber(index),
  email: _mock.email(index),
  lastActivity: _mock.time(index),
  status: randomInArray(['online', 'offline', 'away', 'busy']),
  role: _mock.role(index),
}));

export const _notifications = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  title: [
    'Your order is placed',
    'Sylvan King',
    'You have new message',
    'You have new mail',
    'Delivery processing',
  ][index],
  description: [
    'waiting for shipping',
    'answered to your comment on the Minimal',
    '5 unread messages',
    'sent from Guido Padberg',
    'Your order is being shipped',
  ][index],
  avatar: [null, _mock.image.avatar(2), null, null, null][index],
  type: [
    'order_placed',
    'friend_interactive',
    'chat_message',
    'mail',
    'order_shipped',
  ][index],
  createdAt: _mock.time(index),
  isUnRead: [true, true, false, false, false][index],
}));

export const _mapContact = [
  {
    latlng: [33, 65],
    address: _mock.address.fullAddress(1),
    phoneNumber: _mock.phoneNumber(1),
  },
  {
    latlng: [-12.5, 18.5],
    address: _mock.address.fullAddress(2),
    phoneNumber: _mock.phoneNumber(2),
  },
];

export const _socials = [
  {
    id: getId(),
    value: 'facebook',
    name: 'FaceBook',
    Icon: Facebook,
  },
  {
    id: getId(),
    value: 'instagram',
    name: 'Instagram',
    Icon: Instagram,
  },
  {
    id: getId(),
    value: 'linkedin',
    name: 'Linkedin',
    Icon: Linkedin,
  },
  {
    id: getId(),
    value: 'twitter',
    name: 'Twitter',
    Icon: Twitter,
  },
  {
    id: getId(),
    value: 'youtube',
    name: 'Youtube',
    Icon: Youtube,
  },
  {
    id: getId(),
    value: 'pinterest',
    name: 'Pinterest',
    Icon: Pinterest,
  },
  {
    id: getId(),
    value: 'tiktok',
    name: 'Tiktok',
    Icon: Tiktok,
  },
];
